export { RequestError } from 'request-promise/errors'

export class NotFoundError
  constructor: (resourceName = 'Resource') ->
    @name    = @constructor.name
    @stack   = (new Error).stack
    @message = "#{resourceName} not found"

  @::__proto__ = Error::

export class BadRequestError
  constructor: (@data) ->
    @name    = @constructor.name
    @stack   = (new Error).stack
    @message = "Bad request: #{JSON.stringify(@data)}"

  @::__proto__ = Error::

export class ForbiddenError
  constructor: (@data) ->
    @name    = @constructor.name
    @stack   = (new Error).stack
    @message = "Forbidden: #{JSON.stringify(@data)}"

  @::__proto__ = Error::

export class PreconditionFailed
  constructor: (@data) ->
    @name    = @constructor.name
    @stack   = (new Error).stack
    @message = "Forbidden: #{JSON.stringify(@data)}"

  @::__proto__ = Error::
