import PropTypes      from 'prop-types'
import React          from 'react'
import { withRouter } from 'react-router'
import { Alert      } from 'react-bootstrap'

import { RequestError, ForbiddenError } from 'lib/errors'
import { SwitchWithFallback as FSwitch } from 'alto-react-components/lib/components'
import * as KreditRouter                from 'alto-react-kredit/lib/router'
import * as CanteenRouter               from 'alto-react-canteen/lib/router'
import * as ClientAuthRouter            from 'alto-react-client-auth/lib/router'
import { AuthMessage }                  from 'alto-react-client-auth/lib/components'

import auth     from 'app/auth'
import config   from 'app/config'
import backend  from 'app/backend'
import settings from 'app/settings'

import { LoggedOutNavbar, StaffNavbar, ClientNavbar } from './navbars'

class RootComponent extends React.Component
  constructor: (props) ->
    super(props)

    @state = { tokenChecked: not auth.isLoggedIn, settings: null, error: null }

  @childContextTypes:
    auth:     PropTypes.object
    config:   PropTypes.object
    backend:  PropTypes.object
    settings: PropTypes.object

  getChildContext: -> { auth, config, backend, settings: @state.settings }

  componentWillMount: ->
    @loadSettings()
    @testToken()

  componentWillUnmount: ->
    @loadSettingsPromise?.cancel?()
    @testTokenPromise?.cancel?()

  loadSettings: ->
    @loadSettingsPromise = settings.repository.settings.all()
      .then (settings) =>
        @setState(settings: settings, error: null)
        null # otherwise runaway promise

      .catch RequestError, (error) =>
        @setState(error: "Backend #{config.backendUrl} není dostupný")
        setTimeout(@loadSettings.bind(@), 5 * 1000)
        null # otherwise runaway promise

  testToken: ->
    if auth.isLoggedIn
      @testTokenPromise = auth.testToken()
        .then =>
          @setState(tokenChecked: true, error: null)
          null # otherwise runaway promise

        .catch RequestError, (error) =>
          @setState(error: "Backend #{config.backendUrl} není dostupný")
          setTimeout(@testToken.bind(@), 5 * 1000)
          null # otherwise runaway promise

        .catch ForbiddenError, =>
          auth.logOut()

          @setState tokenChecked: true, =>
            @props.history.push('/' + window.location.search)

          null # otherwise runaway promise

  render: ->
    if @state.error
      <Alert bsStyle='danger'>{@state.error}</Alert>
    else if @state.tokenChecked and @state.settings
      if auth.isLoggedIn
        if auth.loggedInUser.isStaffOrAdmin
          @staffContent()
        else
          @clientContent()
      else
        @loggedOutContent()
    else
      <Alert>Načítám</Alert>

  loggedOutContent: ->
    defaultRoute = (CanteenRouter.defaultLoggedOutRoute(@state.settings) or
                    ClientAuthRouter.defaultLoggedOutRoute(@state.settings))

    <React.Fragment>
      <LoggedOutNavbar />

      <AuthMessage />

      <FSwitch fallback={defaultRoute}>
        {CanteenRouter.loggedOutRoutes(@state.settings)}
        {ClientAuthRouter.loggedOutRoutes(@state.settings)}
      </FSwitch>
    </React.Fragment>

  staffContent: ->
    defaultRoute = KreditRouter.defaultAdminRoute(@state.settings)

    <React.Fragment>
      <StaffNavbar />

      <AuthMessage />

      <FSwitch fallback={defaultRoute}>
        {CanteenRouter.adminRoutes(@state.settings)}
        {KreditRouter.adminRoutes(@state.settings)}
        {ClientAuthRouter.adminRoutes(@state.settings)}
      </FSwitch>
    </React.Fragment>

  clientContent: ->
    defaultRoute = (CanteenRouter.defaultClientRoute(@state.settings, auth) or
                    KreditRouter.defaultClientRoute(@state.settings, auth) or
                    ClientAuthRouter.defaultClientRoute(@state.settings, auth))

    <React.Fragment>
      <ClientNavbar />

      <AuthMessage />

      <FSwitch fallback={defaultRoute}>
        {CanteenRouter.clientRoutes(@state.settings, auth)}
        {KreditRouter.clientRoutes(@state.settings, auth)}
        {ClientAuthRouter.clientRoutes(@state.settings, auth)}
      </FSwitch>
    </React.Fragment>

export default withRouter(RootComponent)
