# global imports

import * as Sentry from '@sentry/browser'

import './index.sass'
import 'react-app-polyfill/ie11'
import 'bootstrap-sass/assets/javascripts/bootstrap'
import 'react-widgets/dist/css/react-widgets.css'
import 'alto-react-components/lib/localization'
import config from './config'

# app

import React                       from 'react'
import ReactDOM                    from 'react-dom'
import { Provider                } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import store         from './store'
import RootComponent from './components/root'

# sentry integration
if config.sentryDsn
  Sentry.init(dsn: config.sentryDsn, environment: config.deployType)

  if config.deployName
    Sentry.configureScope (scope) -> scope.setTag('deploy_name', config.deployName)
  else
    Sentry.withScope (scope) ->
      Sentry.captureMessage('The "CANTEEN_DEPLOY_NAME" environment variable should be provided', 'warning')

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootComponent />
    </Router>
  </Provider>,
  document.getElementById('root'))

# service worker

import * as serviceWorker from './serviceWorker'

# If you want your app to work offline and load faster, you can change
# unregister() to register() below. Note this comes with some pitfalls.
# Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
