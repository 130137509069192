if process.env.NODE_ENV == 'test'
  frontendUrl = 'http://test.canteen.local.nu'
  backendUrl  = 'http://localhost:9001'
else
  frontendUrl = process.env.CANTEEN_FRONTEND_URL ? 'http://localhost:3001'
  backendUrl  = process.env.CANTEEN_BACKEND_URL  ? 'http://localhost:8001'

if process.env.NODE_ENV == 'production'
  deployName = process.env.CANTEEN_DEPLOY_NAME
  deployType = process.env.CANTEEN_DEPLOY_TYPE ? 'unspecified'
  sentryDsn  = process.env.CANTEEN_SENTRY_DSN

export default config = { frontendUrl, backendUrl, deployName, deployType, sentryDsn }
