import React           from 'react'
import { withRouter  } from 'react-router'
import { Nav, Navbar, NavItem } from 'react-bootstrap'

import { NavbarHeader, NavLink } from 'alto-react-components/lib/components'
import { CanteenUserBar        } from 'alto-react-canteen/lib/components'

import * as KreditRouter     from 'alto-react-kredit/lib/router'
import * as CanteenRouter    from 'alto-react-canteen/lib/router'
import * as ClientAuthRouter from 'alto-react-client-auth/lib/router'

export LoggedOutNavbar = (props, context) ->
  <Navbar fluid collapseOnSelect>
    <NavbarHeader />

    <Navbar.Collapse>
      <Nav>
        <CanteenRouter.MenuNavLink>Menu</CanteenRouter.MenuNavLink>
      </Nav>

      <CanteenRouter.CanteenSelectionNavbarForm />

      <Nav pullRight>
        <ClientAuthRouter.LoginNavLink />
        <ClientAuthRouter.RegisterNavLink />
      </Nav>
    </Navbar.Collapse>
  </Navbar>

export StaffNavbar = ->
  <Navbar fluid collapseOnSelect>
    <NavbarHeader />

    <Navbar.Collapse>
      <Nav>
        <NavLink to={KreditRouter.clientManagement      }>Klienti</NavLink>
        <NavLink to={CanteenRouter.canteenManagement    }>Jídelny</NavLink>
        <NavLink to={CanteenRouter.courseGroupManagement}>Skupiny chodů</NavLink>
        <NavLink to={CanteenRouter.courseManagement     }>Chody</NavLink>
        <NavLink to={CanteenRouter.mealManagement       }>Jídla</NavLink>
        <NavLink to={CanteenRouter.orderManagement      }>Objednávky</NavLink>
        <CanteenRouter.TerminalManagementNavLink />
      </Nav>

      <CanteenUserBar />
    </Navbar.Collapse>
  </Navbar>

export ClientNavbar = (props, context) ->
  <Navbar fluid collapseOnSelect>
    <NavbarHeader />

    <Navbar.Collapse>
      <Nav>
        <CanteenRouter.MenuNavLink>Menu</CanteenRouter.MenuNavLink>

        <KreditRouter.TransactionHistoryNavLink>
          Historie transakcí
        </KreditRouter.TransactionHistoryNavLink>
      </Nav>

      <CanteenRouter.CanteenSelectionNavbarForm />

      <CanteenUserBar />
    </Navbar.Collapse>
  </Navbar>
